.texto_listagem_marca {
  font-size: 17px;
  letter-spacing: 0.36px;
  line-height: 21px;
}
.texto_listagem_marca span {
  font-weight: 800;
}

.nocampanha {
  font-size: 11.8px;
  font-weight: 800;
  letter-spacing: 0.25px;
  line-height: 15px;
  color: #999999;
  text-transform: uppercase;
}
.campanha {
  color: #ee7c10;
}
.boxPreco {
  font-size: 18.65px;
  font-weight: 900;
  letter-spacing: 0.19px;
  line-height: 18px;
}
.boxPreco span {
  font-size: 25.54px;
}
.totalW ul {
  width: initial !important;
}

@media (max-width: 480px) {
  .boxPreco {
    font-size: 3.5vw;
    line-height: 3.5vw;
  }
  .boxPreco span {
    font-size: 5vw;
  }
  .nocampanha {
    font-size: 2.5vw;
  }
  .texto_listagem_marca {
    font-size: 3.5vw;
    line-height: 3.5vw;
  }
}
