.noticiaListaItem {
  position: relative;
  border-radius: 38px;
  padding: 20px;
  padding-bottom: 50px;
  transition: all 0.2s ease-in-out;
  margin-bottom: 20px;
  margin-top: 20px;
}
.noticiaListaItem:hover {
  transform: scale(1.025);
}
.tituloFicha {
  font-size: 40px;
  font-weight: 800;
  letter-spacing: 0.41px;
  line-height: 50px;
  text-align: center;
}
.thumbnailZoom {
  display: block;
  position: relative;
  margin: 0 auto;
  overflow: hidden;
}
.imgbEqualNoticia {
  background-position: 50% 50%;
  background-repeat: no-repeat;
  background-size: cover;
  height: 0;
  padding-bottom: 70%;
  overflow: hidden;
  position: relative;
  border-radius: 27px;
  box-shadow: 0 10px 16px 0 rgba(0, 0, 0, 0.19);
}
.imgbEqualNoticia.Equipa {
  background-color: transparent;
  box-shadow: none;
}
.imgbEqualNoticia img {
  object-fit: cover;
  visibility: visible;
  position: absolute;
  width: 100%;
  height: 100%;
  margin: auto;
  left: 0;
  top: 0;
}
.noticiasClientesTitulo {
  font-size: 26px;
  font-weight: 800;
  letter-spacing: 0.27px;
  line-height: 33px;
  margin-bottom: 5px;
  max-height: 70px;
  overflow: hidden;
}
.noticiasClientesData {
  font-size: 16px;
  font-weight: 800;
  letter-spacing: 0.27px;
  line-height: 33px;
  margin-bottom: 5px;
  max-height: 70px;
  overflow: hidden;
}
.noticiasClientesTexto {
  font-size: 16px;
  letter-spacing: 0;
  line-height: 27px;
  height: 135px;
  overflow: hidden;
}
.noticiasClientesData {
  color: #a8a8a8;
  font-size: 16px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 20px;
  height: 22px;
  overflow: hidden;
  margin-bottom: 5px;
}
.fllScreenSvg {
  position: absolute;
  width: 25px;
  right: 10px;
  top: 10px;
  cursor: pointer;
  border: none;
  z-index: 1;
}
.btnPartilharFicha {
  height: 54px;
  width: 67px;
  border-radius: 40px 0 38px 0;
  position: absolute;
  right: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  opacity: 1;
  -webkit-transition: opacity 0.3s ease-in-out;
  transition: opacity 0.3s ease-in-out;
}
.btnPartilharFicha:hover {
  opacity: 0.8;
}
.btnPartilhar {
  font-size: 12px;
  font-weight: 600;
  letter-spacing: 0.3px;
  line-height: 25px;
  transition: all 0.3s;
  display: inline-flex;
  align-items: center;
  cursor: pointer;
  text-transform: uppercase;
}
.tituloPage {
  font-size: 24px;
  font-weight: 700;
  line-height: 30px;
  text-align: center;
  padding-bottom: 5px;
}
.paddingNoticiasClientes {
  padding-left: 20px;
  padding-right: 20px;
}
.NoticiasDestaques {
  color: #fff !important;
  background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, #000000 100%);
  border-radius: 0 0 27px 27px;
  position: absolute;
  bottom: 0;
  width: 100%;
  left: 0;
  z-index: 5;
}

.NoticiasDestaques .noticiasClientesData {
  color: #fff !important;
  font-size: 13px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 16px;
}
.NoticiasDestaques .noticiasClientesTitulo {
  font-size: 22px;
  font-weight: 800;
  letter-spacing: 0.23px;
  line-height: 24px;
}
@media (max-width: 767px) {
  .noticiaListaItem:hover {
    transform: scale(1);
  }
  .tituloFicha {
    font-size: 22px;
    line-height: 30px;
  }
}
@media (max-width: 574px) {
  .noticiaListaItem {
    overflow: hidden;
    padding: 0px;
    padding-bottom: 50px;
  }
  .noticiasClientesTitulo {
    font-size: 22px;
    line-height: 33px;
  }
  .noticiaListaItem .imgbEqualNoticia {
    border-radius: 0px;
  }
}
