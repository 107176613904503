.image-container {
    background-color: #ccc;
    overflow: hidden;
    position: relative;

  }
  .image {
    position: absolute;
    width: 100%;
    height: 100%;
    opacity: 0;
  
    &.thumb {
      opacity: 1;
      filter: blur(10px);
      transition: opacity 1s ease-out;
      position: absolute;
      z-index: 1;
      &.isLoaded {
        opacity: 0;      
      }
    }  
    &.isLoaded {
      transition: opacity 1s ease-out;
      opacity: 1;
      z-index: 2;
    }
  }