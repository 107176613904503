.box {
  width: 100vw;
  height: 100vh;

  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
}

.black {
  background: #000000;
}
.white {
  background: #ffffff;
}
