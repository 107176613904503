.buttonNext {
  right: 0px;
  left: auto;
  position: absolute;
  top: 40%;
  z-index: 10;
  cursor: pointer;
  width: 40px;
  display: flex;
  justify-content: center;
}
.buttonPrev {
  left: 0px;
  right: auto;
  position: absolute;
  top: 40%;
  z-index: 10;
  cursor: pointer;
  width: 40px;
  display: flex;
  justify-content: center;
}
.buttonDisable {
  opacity: 0.35;
  cursor: auto;
  pointer-events: none;
}
.buttonLock {
  display: none;
}
.buttonNextD {
  right: 31vw;
}
.buttonPrevD {
  left: 31vw;
}
.buttonNextB {
  right: 4vw;
}
.buttonPrevB {
  left: 4vw;
}
.buttonNextR {
  top: 27%;
}
.buttonPrevR {
  top: 27%;
}

@media (max-width: 1199px) {
  .buttonNextD {
    right: 30vw;
  }
  .buttonPrevD {
    left: 30vw;
  }
}

@media (max-width: 991px) {
  .buttonNextD {
    right: 0;
  }
  .buttonPrevD {
    left: 0vw;
  }
}
