.btnPesquisaModelo {
  width: 100%;
  align-items: center;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  line-height: 30px;
  background-position: calc(100% - 8px) 18px;
  background-repeat: no-repeat;
  height: 100%;
}

.btnValorSelect {
  position: absolute;
  top: 0;
  right: 0;
  z-index: 0;
  background-position: calc(100% - 8px) 14px;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  height: 38px;
  border: none;
  width: 100%;
  background-repeat: no-repeat;
  padding: 0.375rem 0.75rem;
  border-radius: 5px;
}
.btnValorInput {
  position: relative;
  z-index: 1;
  width: calc(100% - 35px);
  border: 0;
  box-shadow: none;
  padding: 0.375rem 0.75rem;
  height: 38px;
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

.section {
  position: relative;
  margin-top: -70px;
  z-index: 10;
}
.sectionFloat {
  position: absolute;
  top: 10vw;
  z-index: 10;
}
.rowPesquisaReduzinda {
  padding-top: 30px;
  padding-bottom: 14px;
}
.colPesquisaMenu input {
  border: none;
}
.colPesquisaMenu input:focus {
  box-shadow: none;
}
.colPesquisaMenu {
  min-height: 70px;
  border-radius: 70px;
  padding: 0px 25px !important;
}

.colPesquisaFloat {
  border-radius: 35px;
  width: 475px !important;
  max-width: 475px !important;
  margin: auto !important;
  padding: 0px 30px !important;
}
.colPesquisaFloatBottom {
  border-radius: 52.5px;
  width: 590px !important;
  max-width: 590px !important;
  margin: auto !important;
  padding: 0px 30px !important;
}
.colPesquisaRapidoBox {
  position: relative;
  border-radius: 52.5px;
  width: 590px !important;
  max-width: 590px !important;
  margin: auto !important;
  padding: 0px 30px !important;
}
.colPesquisaRapidoInline {
  position: relative;
  border-radius: 52.5px;
  width: 664px !important;
  max-width: 664px !important;
  margin: auto !important;
  padding: 0px 30px !important;
}
.colPesquisaFacilInline {
  border-radius: 70px;
  width: 991px !important;
  max-width: 991px !important;
  margin: auto !important;
  padding: 0px 30px !important;
}

.colPesquisaPaddingAvan {
  padding-top: 15px !important;
  max-width: 1200px !important;
  width: 100% !important;
  margin: auto !important;
  border-radius: 35px;
}
.btnPequisa {
  text-transform: uppercase;
  align-items: center;
  margin: auto;
  justify-content: center;
  width: 315px;
  height: 45px;
  outline: 0;
  border: none;
  border-radius: 22.5px;
  color: #ffffff;
  position: relative;
  display: flex;
  align-items: center;
}

.btnPequisa::before {
  border-radius: 22.5px;
  width: 315px;
  height: 45px;
}

.btnPequisaBox {
  position: absolute;
  right: 0;
  left: 0;
}
.textoLimpar {
  margin: auto;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 10px;
  margin-top: 10px;
  width: fit-content;
  font-size: 12px;
  font-weight: 500;
  letter-spacing: 0.23px;
  line-height: 31px;
  text-align: center;
  border-radius: 22.5px;
  width: 245px;
  text-transform: uppercase;
}
.textoLimpar svg {
  margin-right: 5px;
}
.boxShadowPares {
  min-height: 46px;
  position: relative;
  border-radius: 30px;
}
.boxShadowPares label {
  position: absolute;
  top: -9px;
  left: 20px;
  padding-left: 2px;
  padding-right: 2px;
  z-index: 1;
  font-size: 11px;
  font-weight: 500;
  letter-spacing: 0.24px;
  line-height: 14px;
  text-transform: uppercase;
}

.titulo {
  height: 34px;
  width: 335px;
  border-radius: 35px 35px 0 0;

  color: #ffffff;
  font-size: 14px;
  font-weight: bold;
  letter-spacing: 0.26px;
  line-height: 34px;
  text-align: center;
  margin: auto;
}

.tituloReduzido {
  font-size: 25px;
  font-weight: bold;
  letter-spacing: 0.47px;
  line-height: 31px;
  text-shadow: 0 2px 7px 0 rgba(0, 0, 0, 0.5);
}

.titulo_nome {
  font-size: 14px;
  font-weight: 600;
  letter-spacing: 0.3px;
  line-height: 70px;
  text-transform: uppercase;
}
.btn_avancada {
  font-size: 12px;
  font-weight: 500;
  letter-spacing: 0.23px;
  text-align: center;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: auto;
  margin-top: 20px;
  line-height: 31px;
  border-radius: 22.5px;
  width: 245px !important;
  text-transform: uppercase;
}
.btn_avancada svg {
  margin-right: 5px;
}
.btn_avancada span {
  position: absolute;
  font-size: 11px;
}
.btnPesquisa {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  height: 55px;
  width: 55px;
  border-radius: 50%;
  outline: 0;
  border: none;
  position: relative;
  color: #ffffff;
}
.btnPesquisa::before {
  border-radius: 50%;
  height: 55px;
  width: 55px;
}

.btnPesquisa span {
  position: absolute;
  font-size: 11px;
}

.btnPesquisaLarge {
  width: 100%;
  border-radius: 22.5px;
  height: 45px;
  flex-direction: row;
}
.btnPesquisaLarge img {
  margin-right: 5px;
}
.btnPesquisaLarge::before {
  width: 100%;
  border-radius: 22.5px;
  height: 45px;
}

.btnPesquisaPosition {
  position: absolute;
  margin-top: -8px;
  width: calc(100% - 24px);
}

.btnPesquisaRapidaInline .tab {
  display: flex;
  align-items: center;
  flex-direction: column;
  text-transform: uppercase;

  font-size: 12px;
  font-weight: 600;
  letter-spacing: 0.26px;
  line-height: 15px;
  text-align: center;
}
.tab > svg {
  height: 20px;
  margin-bottom: 10px;
}
.tab > div:first-child {
  display: flex;
  align-items: center;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
}
.tab > div:last-child {
  height: 2px;
  width: 0%;
  border-radius: 1.5px;
  margin-top: 10px;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
}

.colPesquisaPadding {
  width: 760px !important;
  max-width: 760px !important;
  margin: auto !important;
}

.colPesquisaPaddingAvan > form > div > div:nth-child(3) {
  height: 70px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding: 0;
  position: relative;
  right: -5px;
}
.carroucel {
  overflow-y: hidden;
  overflow-x: auto;
  width: 100%;
  flex-wrap: nowrap !important;
}
@media only screen and (max-width: 1119px) {
  .colPesquisaFacilInline {
    width: 100% !important;
    max-width: 100% !important;
  }
}

@media only screen and (max-width: 991px) {
  .sectionFloat {
    top: auto;
    position: relative;
    margin-top: -70px;
  }

  .colPesquisaPadding {
    width: 100% !important;
    max-width: 100% !important;
    padding: 8px 35px !important;
  }

  .colPesquisaPaddingAvan > form > div > div:nth-child(2) {
    height: 70px;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
  .colPesquisaPaddingAvan > form > div > div:nth-child(3) {
    height: 70px;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    padding: 0;
    position: relative;
    right: -15px;
  }
  .titulo_nome {
    font-size: 10px;
    line-height: 13px;
  }
  .titulo_nome + input {
    padding: 0;
  }
}

@media only screen and (max-width: 767px) {
  .colPesquisaFloatBottom {
    border-radius: 35px;
    width: 100% !important;
    max-width: 100% !important;
  }
  .barraPesquisa {
    width: calc(100% - 55px) !important;
  }
  .colPesquisaFacilInline {
    border-radius: 35px;
  }
  .colPesquisaRapidoInline {
    width: 100% !important;
    max-width: 100% !important;
  }
  .colPesquisaRapidoBox {
    width: 100% !important;
    max-width: 100% !important;
  }
  .boxShadowParesRange {
    border-radius: 22.5px;
  }
  .arrow {
    width: 15px;
    height: auto;
  }
  .btnPesquisaModelo {
    background-size: 15px auto;
  }
}

@media only screen and (max-width: 575px) {
  .barraPesquisa {
    width: calc(100% - 79px) !important;
  }
  .tituloReduzido {
    font-size: 20px;
    letter-spacing: 0.37px;
    line-height: 25px;
  }
  .colPesquisaRapidoBox {
    border-radius: 35px;
  }
  .colPesquisaFloat {
    width: 100% !important;
    max-width: 100% !important;
  }
  .sectionFloat {
    margin-right: -12px;
    margin-left: -12px;
  }
  .colPesquisaMenu {
    padding-right: 0.75rem !important;
    padding-left: 0.75rem !important;
  }
  .colPesquisaPadding {
    width: calc(100% - 1.5rem) !important;
    max-width: calc(100% - 1.5rem) !important;
  }
  .titulo {
    font-size: 11px;
    width: 75%;
    height: 27px;
    line-height: 27px;
  }
  .btn_avancada {
    margin-top: 15px;
  }
}

@media only screen and (max-width: 345px) {
  .btnPequisa {
    width: 300px;
  }
  .btnPequisa::before {
    width: 300px;
  }
}
