.frame {
  padding-bottom: 100%;
  position: relative;
  border-radius: 50%;
  overflow: hidden;
  background-color: #d8d8d8;
  margin-bottom: 5px;
  transition: all 0.2s ease-in-out;
  margin-top: 10px;
}
.frame::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  border-radius: 50%;
  padding: 10px;
  z-index: 1;
}

.frame:hover,
.frame:focus {
  transform: scale(1.025);
}

.frame img {
  border-radius: 50%;
}

.frameSquare {
  padding-bottom: 100%;
  position: relative;
  border-radius: 23px;
  overflow: hidden;
  background-color: #d8d8d8;
  margin-bottom: 5px;
  transition: all 0.2s ease-in-out;
  margin-top: 10px;
}
.frameSquare::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  border-radius: 23px;
  padding: 10px;

  -webkit-mask-composite: xor;
  mask-composite: exclude;
  z-index: 1;
}

.frameSquare:hover,
.frameSquare:focus {
  transform: scale(1.025);
}

.frameSquare img {
  border-radius: 23px;
}

.imagem {
  -o-object-fit: cover;
  object-fit: cover;
  visibility: visible;
  position: absolute;
  width: 100%;
  height: 100%;
}

.frame img {
  border-radius: 50%;
}

.marca {
  font-size: 17px;
  font-weight: 800;
  letter-spacing: 0.46px;
  line-height: 21px;
  text-align: center;
}
.modelo {
  font-size: 15px;
  letter-spacing: 0.41px;
  line-height: 19px;
  text-align: center;
}

@media (max-width: 767px) {
  .frame::before {
    padding: 5px;
  }
  .frame:hover,
  .frame:focus {
    transform: scale(1);
  }
}
@media (max-width: 575px) {
  .marca {
    font-size: 12px;
  }
  .modelo {
    font-size: 12px;
  }
}
