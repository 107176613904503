.retangle {
  position: relative;
  border-radius: 27px;
  padding: 20px;
  transition: all 0.2s ease-in-out;
  margin-bottom: 40px;
  margin-top: 40px;
  height: 100px;
  position: relative;
}

.retangle:hover,
.retangle:focus {
  transform: scale(1.025);
}

.retangle > img {
  position: absolute;
  width: calc(100% - 40px);
  height: calc(100% - 40px);
  object-fit: contain;
  top: 20px;
  right: 20px;
}

.retangle > p {
  font-weight: 700;
  font-size: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  width: calc(100% - 40px);
  height: calc(100% - 40px);
  object-fit: contain;
  top: 20px;
  right: 20px;
}
@media only screen and (max-width: 767px) {
  .retangle {
    padding: 10px;
  }
}
